
























































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { SiteStateModule, SiteType } from '@/store/site_state'
import LineChart from './charts/LineChart.vue'
import tabButtonGroup from './tab-button-group.vue'
import moment, { Moment } from 'moment'
import { LOCAL_STORAGE_ITEM } from '@/utils/constant'
import { lsGet } from '@/utils/util'

@Component({
  name: 'Home',
  components: {
    'line-chart': LineChart,
    tabButtonGroup
  }
})
export default class Home extends Vue {
  [x: string]: any
  aduitAccountNum = ''
  aduitCampaignNum = ''
  aduitTemplateNum = ''
  aduitBrandNameNum = ''
  top5Templateis = true
  top5Campainis = true
  top5Accountis = true
  nickName = 'AdminAdmin'
  hyperSMSCampaign = null
  smsCampaign = null
  smsTask = null
  hyperSMSTemplate = null
  smsTemplate = null
  dataTrendRadio: any = 2
  dataTrendRange: string[] = []
  trendChartData = []
  echartsHyperData: any = []
  echartsSmsData: any = []
  chartFields: any = [
    'totalHyperSMS',
    'totalDelivered',
    'totalUnDelivered',
    'totalProcessing'
  ]
  chartAlias: any = {
    totalHyperSMS: 'Total HyperSMS',
    totalDelivered: 'Total Delivered',
    totalUnDelivered: 'Total UnDelivered',
    totalProcessing: 'Total Processing'
  }
  top5Account: any = []
  top5Campain: any = []
  top5Template: any = []
  dateOptions = [
    { label: 'Today', days: 1, alias: 'Day' },
    { label: 'The last 7 days', days: 7, alias: undefined },
    { label: 'The last 15 days', days: 15, alias: undefined },
    { label: 'The last 30 days', days: 30, alias: 'Month' }
  ]
  account5Top = 7
  campain5Top = 7
  template5Top = 7

  msgType = 'HyperSMS'

  msgTypeBtns = [
    {
      type: 'HyperSMS',
      label: 'HyperSMS',
      key: 1
    },
    {
      type: 'SMS',
      label: 'SMS',
      key: 2
    }
  ]
  top5AccountTotal: any = 1000000
  top5CampainTotal: any = 1000000
  top5TemplateTotal: any = 1000000
  /**
   * 数据趋势类型切换处理
   */
  changeMessage(type: any) {
    // 切换消息类型
    if (type === 1) {
      this.msgType = 'HyperSMS'
    } else if (type === 2) {
      this.msgType = 'SMS'
    }
    // 获取天数
    let days
    if (this.dataTrendRadio === 1) {
      days = 1
    } else if (this.dataTrendRadio === 2) {
      days = 7
    } else if (this.dataTrendRadio === 3) {
      days = 30
    } else {
      days = this.differDays(this.dataTrendRange[0], this.dataTrendRange[1])
    }
    this.echartsDataChange(type, days)
  }
  toPrice2(num: string) {
    var source = num.split('.')
    source[0] = source[0].replace(
      new RegExp('(\\d)(?=(\\d{3})+$)', 'ig'),
      '$1,'
    )
    return source.join('.')
  }

  // 计算日期相差天数, 添加绝对值处理数据
  differDays(startDay: any, endDay: any) {
    let endTime: any = new Date(endDay).getTime() / 1000
    let startTime: any = new Date(startDay).getTime() / 1000
    let differTime: any = Math.abs(endTime - startTime)
    let timeDay = Math.floor(differTime / 60 / 60 / 24) + 1
    return timeDay
  }

  changeDataTrendRadio(e: any) {
    let date = new Date()
    let days, types
    if (e.target.value === 1) {
      this.dataTrendRange = [
        moment(date).format('YYYY-MM-DD'),
        moment(date).format('YYYY-MM-DD')
      ]
      days = 1
    } else if (e.target.value === 2) {
      this.dataTrendRange = [
        moment(date).subtract(6, 'days').format('YYYY-MM-DD'),
        moment(date).format('YYYY-MM-DD')
      ]
      days = 7
    } else if (e.target.value === 3) {
      this.dataTrendRange = [
        moment(date).subtract(29, 'days').format('YYYY-MM-DD'),
        moment(date).format('YYYY-MM-DD')
      ]
      days = this.differDays(this.dataTrendRange[0], this.dataTrendRange[1])
    }
    if (this.msgType === 'HyperSMS') {
      types = 1
    } else {
      types = 2
    }
    this.changeDateEchartsData(types, days)
  }

  msgAssets: any = []

  get site() {
    return SiteStateModule.site
  }

  get account() {
    const user = SiteStateModule.userInfo
    if (user === null)
      return {
        accountId: null,
        accountLevel: null,
        accountName: null,
        accountType: null,
        userLevel: null,
        username: null
      }
    return {
      accountId: user.accountId,
      accountLevel: user.accountLevel,
      accountName: user.accountName,
      accountType: user.accountType,
      userLevel: user.accountLevel,
      username: user.username
    }
  }

  get showBalance() {
    return SiteStateModule.site !== 'admin'
  }

  get isManagerSite() {
    return SiteStateModule.site === 'admin' || SiteStateModule.site === 'agent'
  }

  goto(route: any) {
    this.$router.push({ path: route })
  }
  selectPriceDate = ''
  offsetDays = 2678400 * 1000

  //选择开始时间/结束时间

  calendarPriceRangeChange = (val: string[]) => {
    this.selectPriceDate = val[0]
  }
  //根据选择的开始时间/结束时间，动态渲染要禁用的日期
  formatDate(date: any) {
    var y = date.getFullYear()
    var m = date.getMonth() + 1
    m = m < 10 ? '0' + m : m
    var d = date.getDate()
    d = d < 10 ? '0' + d : d
    return y + '-' + m + '-' + d
  }
  disabledPriceRangeDate = (current: Moment) => {
    if (this.selectPriceDate) {
      let selectV = moment(this.selectPriceDate, 'YYYY-MM-DD').valueOf()
      return (
        current >
          moment(this.formatDate(new Date(selectV + this.offsetDays))) ||
        current <
          moment(this.formatDate(new Date(selectV - this.offsetDays))) ||
        current > moment().endOf('day')
      )
    } else {
      return current > moment().endOf('day')
    }
  }

  changeRange() {
    // this.selectPriceDate = ''
    let types, days
    this.dataTrendRadio = null
    let dayStart = moment(this.dataTrendRange[0]).format('YYYY-MM-DD')
    let dayEnd = moment(this.dataTrendRange[1]).format('YYYY-MM-DD')
    this.dataTrendRange = [dayStart, dayEnd]
    days = this.differDays(this.dataTrendRange[0], this.dataTrendRange[1])
    if (this.msgType === 'HyperSMS') {
      types = 1
    } else {
      types = 2
    }
    this.changeDateEchartsData(types, days)
  }

  echartsDataChange(type: any, days: any) {
    let arrData: any = []
    if (type === 1) {
      this.chartFields = [
        'totalHyperSMS',
        'totalDelivered',
        'totalUnDelivered',
        'totalProcessing'
      ]
      this.chartAlias = {
        totalHyperSMS: 'Total HyperSMS',
        totalDelivered: 'Total Delivered',
        totalUnDelivered: 'Total UnDelivered',
        totalProcessing: 'Total Processing'
      }
      this.trendChartData = this.echartsHyperData
    } else {
      this.chartFields = [
        'totalSMS',
        'totalDelivered',
        'totalUnDelivered',
        'totalProcessing'
      ]
      this.chartAlias = {
        totalSMS: 'Total SMS',
        totalDelivered: 'Total Delivered',
        totalUnDelivered: 'Total UnDelivered',
        totalProcessing: 'Total Processing'
      }
      this.trendChartData = this.echartsSmsData
    }
  }

  async changeDateEchartsData(type: any, days: any) {
    let params = {
      dateBegin: this.dataTrendRange[0],
      dateEnd: this.dataTrendRange[1],
      idClient: this.account?.accountId,
      topDay: days,
      type: 0
    }
    this.trendChartData = []
    let url = '/api/hyper_fast/report/common/toptrends'
    const { data } = await this.$http.post(url, params)
    if (data.code === 0 && data.data) {
      let result = data.data.data.trends
      let trendHypersms = result.trendHypersms
      this.echartsHyperData = this.filterEchartsData(trendHypersms)
      let trendSms = result.trendSms
      this.echartsSmsData = this.filterEchartsData(trendSms)
      if (type === 1) {
        this.trendChartData = this.echartsHyperData
      } else {
        this.trendChartData = this.echartsSmsData
      }
    }
  }
  async getHomeTotal(url: string, type: string) {
    const { data } = await this.$http.post(url)
    if (data.code === 0 && data.data) {
      this[type] = data.data
    }
  }
  async getHomeDataInfo() {
    console.log(this.account)
    let url = '/api/hyper_fast/report/common/indexInfo'
    let params: any = {
      dateBegin: '',
      dateEnd: '',
      idClient: this.account?.accountId,
      topDay: 7
    }
    const { data } = await this.$http.post(url, params)
    if (data.code === 0 && data.data) {
      // 余额信息
      let appConfigData = data.data.data
      this.msgAssets = []
      this.msgAssets.push(appConfigData.financial)
      // 发送信息总栏
      let fiveCardStart = appConfigData.stat
      this.hyperSMSCampaign = fiveCardStart.hypersmCampaignCount
      this.smsCampaign = fiveCardStart.smsCampaignCount
      this.smsTask = fiveCardStart.smsTaskCount
      this.hyperSMSTemplate = fiveCardStart.hypersmsTplCount
      this.smsTemplate = fiveCardStart.smsTplCount
    }
  }

  clickTopChange(e: any, name: any) {
    if (name === 1) {
      this.top5Accountis = true
      this.account5Top = e
      this.searchClickTop(this.account5Top, name)
    } else if (name === 2) {
      this.top5Campainis = true
      this.campain5Top = e
      this.searchClickTop(this.campain5Top, name)
    } else if (name === 3) {
      this.top5Templateis = true
      this.template5Top = e
      this.searchClickTop(this.template5Top, name)
    }
  }

  async searchClickTop(day: any, type: any) {
    let url = '/api/hyper_fast/report/common/topbusiness'
    let params: any = {
      idClient: this.account?.accountId,
      topDay: day,
      type: type
    }
    const { data } = await this.$http.post(url, params)
    let needData = data.data

    // 根据top系列 type 返回
    if (type === 1) {
      this.top5Accountis = false

      this.top5Account = needData
      this.top5AccountTotal = 0
      this.top5Account.forEach((items: any) => {
        this.top5AccountTotal += Number(items.value)
      })
    } else if (type === 2) {
      this.top5Campainis = false
      this.top5Campain = needData
      this.top5CampainTotal = 0
      this.top5Campain.forEach((items: any) => {
        this.top5CampainTotal += Number(items.value)
      })
    } else if (type === 3) {
      this.top5Templateis = false
      this.top5Template = needData
      this.top5TemplateTotal = 0
      this.top5Template.forEach((items: any) => {
        this.top5TemplateTotal += Number(items.value)
      })
    }
  }

  filterEchartsData(data: any) {
    let type = 1
    if (data.templateType === 'HyperSms') {
      type = 1
    } else if (data.templateType === 'sms') {
      type = 2
    }
    return this.getEchartsDataFun(data.data, type)
  }
  getEchartsDataFun(data: any, type: number) {
    if (data) {
      let arr: any = []
      for (let i in data) {
        let newDataObj: any = {}
        for (let k in data[i]) {
          if (k === 'total') {
            if (type === 1) {
              newDataObj['totalHyperSMS'] = data[i][k]
            } else {
              newDataObj['totalSMS'] = data[i][k]
            }
          } else if (k === 'success') {
            newDataObj['totalDelivered'] = data[i][k]
          } else if (k === 'fail') {
            newDataObj['totalUnDelivered'] = data[i][k]
          } else if (k === 'other') {
            newDataObj['totalProcessing'] = data[i][k]
          }
        }
        newDataObj['date'] = i
        arr.push(newDataObj)
      }
      return arr
    }
  }

  created() {
    this.dataTrendRange = [
      moment(new Date()).subtract(6, 'days').format('YYYY-MM-DD'),
      moment(new Date()).format('YYYY-MM-DD')
    ]
    this.msgAssets = [
      {
        balance: '0',
        currency: 'VND'
      }
    ]
  }
  hasBtnPermissions(permission: string) {
    const MyBtns: any = lsGet(LOCAL_STORAGE_ITEM.LS_SEC_MENU_LIST)
    return MyBtns.indexOf(permission) > -1
  }
  mounted() {
    // mbf 读取下面接口； vtt首页还未开发
    if (process.env.VUE_APP_THEME === 'vnmbf') {
      // 要根据不同客户端进行数据调用
      let clientType = this.site
      this.getHomeDataInfo()
      this.searchClickTop(7, 1)
      this.searchClickTop(7, 2)
      this.searchClickTop(7, 3)
      this.changeDataTrendRadio({ target: { value: 2 } })

      this.getHomeTotal(
        '/api/hyper_fast/report/common/auditingAccountTotal',
        'aduitAccountNum'
      )
      this.getHomeTotal(
        '/api/hyper_fast/report/common/auditingBrandnameTotal',
        'aduitBrandNameNum'
      )
      this.getHomeTotal(
        '/api/hyper_fast/report/common/auditingCampaignTotal',
        'aduitCampaignNum'
      )
      this.getHomeTotal(
        '/api/hyper_fast/report/common/auditingTemplateTotal',
        'aduitTemplateNum'
      )
    } else {
      this.getHomeDataInfo()
      this.searchClickTop(7, 1)
      this.searchClickTop(7, 2)
      this.searchClickTop(7, 3)
      this.changeDataTrendRadio({ target: { value: 2 } })

      this.getHomeTotal(
        '/api/hyper_fast/report/common/auditingAccountTotal',
        'aduitAccountNum'
      )
      this.getHomeTotal(
        '/api/hyper_fast/report/common/auditingBrandnameTotal',
        'aduitBrandNameNum'
      )
      this.getHomeTotal(
        '/api/hyper_fast/report/common/auditingCampaignTotal',
        'aduitCampaignNum'
      )
      this.getHomeTotal(
        '/api/hyper_fast/report/common/auditingTemplateTotal',
        'aduitTemplateNum'
      )
    }
  }
}
